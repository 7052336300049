import { classNames } from '../formatting';

import {
  ArrowDownIcon,
  ArrowUpIcon,
} from '@heroicons/react/20/solid';

export default function ChangeIndicator({ change }) {
  let changeType = change < 0 ? 'decrease' : 'increase';
  let changeVal = `${Math.abs(change)}%`;

  return (
    <p
      className={classNames(
        changeType === 'increase' ? 'text-green-600' : 'text-red-600',
        'flex items-baseline text-sm font-semibold'
      )}
    >
      {changeType === 'increase' ? (
        <ArrowUpIcon
          className="h-4 w-4 flex-shrink-0 self-center text-green-500"
          aria-hidden="true"
        />
      ) : (
        <ArrowDownIcon
          className="h-4 w-4 flex-shrink-0 self-center text-red-500"
          aria-hidden="true"
        />
      )}

      <span className="sr-only">
        {' '}
        {changeType === 'increase' ? 'Increased' : 'Decreased'} by{' '}
      </span>
      {changeVal}
    </p>
  );
};
