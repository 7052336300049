export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function changePercentage(current, preceding) {
  if (preceding == 0) {
    return current == 0 ? 0 : 100;
  }
  return Math.round((100 * (current - preceding)) / preceding);
}

export function nanoToMin(nano) {
  const minuteNanos = 60000000000;

  return nano / minuteNanos;
}

// minsToDuration prints in the format: 10m20s, and if
// there are no seconds, just: 10m
export function minsToDuration(mins) {
  const minutes = Math.floor(mins);
  const seconds = Math.round((mins - minutes) * 60);

  if (seconds > 0) {
    return `${minutes}m${seconds}s`;
  }

  return `${minutes}m`;
}

export function formatDuration(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60); // Floor seconds to avoid decimals

  const parts = [];
  if (h > 0) parts.push(`${h}h`);
  if (m > 0) parts.push(`${m}m`);
  if (s > 0 || parts.length === 0) parts.push(`${s}s`);

  return parts.join("");
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + sizes[i];
}
