import { useEffect, useState } from 'react';
import { classNames } from './formatting';

import { Square2StackIcon, CheckIcon } from '@heroicons/react/24/outline';

const SSH_DEBUG_DOCS_URL = 'https://docs.actuated.dev/tasks/debug-ssh/';

function CopyButton({ text, children }) {
  let [copyCount, setCopyCount] = useState(0);
  let copied = copyCount > 0;

  useEffect(() => {
    if (copyCount > 0) {
      let timeout = setTimeout(() => setCopyCount(0), 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copyCount]);

  return (
    <button
      type="button"
      className={classNames(
        'bg-transparent hover:bg-gray-200/70 rounded-md transition duration-300',
        children ?
          'rounded-md py-2 px-3 text-s font-medium ring-1 ring-inset text-gray-600 bg-gray-50 ring-gray-500/10' : 'py-2'
      )}
      onClick={() => {
        window.navigator.clipboard.writeText(text).then(() => {
          setCopyCount((count) => count + 1);
        });
      }}
    >
      {children && <span>{children}</span>}

      <span className="relative p-5">
        <Square2StackIcon
          aria-hidden={copied}
          className={classNames(
            'h-5 w-5 text-grey-500',
            'absolute pointer-events-none transition duration-300',
            children
              ? 'right-0 top-0 bottom-0 my-auto'
              : 'top-0 left-0 bottom-0 right-0 m-auto',
            copied && 'opacity-0'
          )}
        />

        <CheckIcon
          aria-hidden={!copied}
          className={classNames(
            'h-5 w-5 text-grey-500',
            'absolute pointer-events-none transition duration-300',
            children
              ? 'right-0 top-0 bottom-0 my-auto'
              : 'top-0 left-0 bottom-0 right-0 m-auto',
            !copied && 'opacity-0'
          )}
        />
      </span>
    </button>
  );
}

function SSHDebugPage({ api }) {
  const [sessions, setSessions] = useState([]);
  const [refreshing, setRefreshing] = useState(false);

  const updateSessions = async () => {
    setRefreshing(true);

    let sessions = await api.getDebugSessions();

    setSessions(sessions);
    setRefreshing(false);
  };

  useEffect(() => {
    updateSessions();
  }, []);

  return (
    <main>
      <div className="py-6">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="h-96">
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                      SSH Sessions
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                      Connect to a runner via SSH
                    </p>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                      onClick={() => updateSessions()}
                      type="button"
                      disabled={refreshing}
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                      {refreshing ? (
                        <svg
                          className="animate-spin h-5 w-5 mr-3"
                          viewBox="0 0 24 24"
                          visibility={refreshing ? 'show' : 'hidden'}
                        >
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        ''
                      )}
                      {refreshing ? 'Refreshing' : 'Refresh'}
                    </button>
                  </div>
                </div>
                <div className="mt-8 flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                              >
                                Hostname
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                SSH Command
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                RX
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                TX
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Duration
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Connections
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {sessions && sessions.length ? (
                              sessions.map((session) => (
                                <tr key={session.hostname}>
                                  <td className="whitespace-nowrap px-2 py-4 py-2 pr-3 sm:pl-6 pl-2 text-sm font-medium text-gray-900">
                                    {session.hostname}
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                    <div className="flex items-center">
                                      <span className="mr-3 hidden xl:block">
                                        {session.command}
                                      </span>
                                      <div className="hidden xl:block">
                                        <CopyButton text={session.command} />
                                      </div>
                                      <div className="xl:hidden">
                                        <CopyButton text={session.command}>
                                          Copy
                                        </CopyButton>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                    {`${session.rx}B`}
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                    {`${session.tx}B`}
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                    {session.duration}
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                    {session.connections}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr key="empty-row">
                                <td
                                  className="whitespace-nowrap px-2 py-4 py-2 pr-3 sm:pl-6 pl-2 text-sm text-gray-900"
                                  colSpan={8}
                                >
                                  No active sessions,{' '}
                                  <a
                                    className="underline text-indigo-600 hover:text-indigo-500"
                                    href={SSH_DEBUG_DOCS_URL}
                                  >
                                    click here to get started
                                  </a>
                                  .
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /End replace */}
        </div>
      </div>
    </main>
  );
}

export default SSHDebugPage;
