import { changePercentage, nanoToMin } from './formatting';

const aggregateJobStats = (stats) => stats.reduce(
  (accumulator, value) => ({
    builds: accumulator.builds + value.totalBuilds,
    runtime: accumulator.runtime + value.totalRunTimeNano,
    success: accumulator.success + value.totalSuccess,
    failed: accumulator.failed + value.totalFailure,
    cancelled: accumulator.cancelled + value.totalCancelled,
  }), {
    builds: 0,
    runtime: 0,
    success: 0,
    failed: 0,
    cancelled: 0,
  },
)

export const getJobSummary = (stats) => {
  let currentTotal = aggregateJobStats(stats.map((stat) => stat.current));
  let precedingTotal = aggregateJobStats(stats.map((stat) => stat.preceding));

  return {
    builds: {
      stat: currentTotal.builds,
      preceding: precedingTotal.builds,
      change: changePercentage(currentTotal.builds, precedingTotal.builds),
    },
    runtime: {
      stat: Math.round(nanoToMin(currentTotal.runtime), 2),
      preceding: Math.round(nanoToMin(precedingTotal.runtime), 2),
      change: changePercentage(currentTotal.runtime, precedingTotal.runtime),
    },
    success: {
      stat: currentTotal.success,
      preceding: precedingTotal.success,
      change: changePercentage(currentTotal.success, precedingTotal.success),
    },
    failed: {
      stat: currentTotal.failed,
      preceding: precedingTotal.failed,
      change: changePercentage(currentTotal.failed, precedingTotal.failed),
    },
    cancelled: {
      stat: currentTotal.cancelled,
      preceding: precedingTotal.cancelled,
      change: changePercentage(
        currentTotal.cancelled,
        precedingTotal.cancelled
      ),
    },
  }
}