export default function Steps({ steps }) {
  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {steps.map((step, stepIdx) => (
          <li key={step.id}>
            <div className="relative pb-8">
              {stepIdx !== steps.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-100"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span className="h-8 w-8 text-sm bg-gray-100 text-gray-500 rounded-full flex items-center justify-center ring-8 ring-white">
                    {step.id}
                  </span>
                </div>
                <div className="flex flex-col grow min-w-0">
                  <h2 className="text-xl font-semibold text-gray-900">
                    {step.title}
                  </h2>
                  <div className="mt-4 prose lg:max-w-none lg:prose-lg">{step.content}</div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
