import { Component } from 'react';
import JobQueueTable from './JobQueueTable';

class JobQueue extends Component {

  constructor(props) {
    super(props);
    
    this.state = {refreshing: false, jobs: []}

    this.onRefresh = this.onRefresh.bind(this);
  }

  onRefresh() {
    this.setState({ refreshing: true });
    this.props.api.getJobQueue().then((data) => {
      this.setState({ jobs: data });
      this.setState({ refreshing: false });
    });
  }

  componentDidMount() {
    this.setState({ refreshing: true });
    this.props.api.getJobQueue().then((data) => {
      this.setState({ jobs: data });
      this.setState({ refreshing: false });
    });
  }

  render() {
    return (
    <main>
      <div className="py-6">

        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div className="h-96">
            <JobQueueTable refreshing={this.state.refreshing} jobs={this.state.jobs} onRefresh={this.onRefresh}></JobQueueTable>
            </div>
          </div>
        </div>
    </div>
  </main>
    )
  }
}

export default JobQueue;