import Steps from './components/Steps';

const steps = [
  {
    id: 1,
    title: 'Install arkade',
    content: (
      <>
        <pre>
          <code>
            {`# Note: you can also run without \`sudo\` and move the binary yourself
curl -sLS https://get.arkade.dev | sudo sh

# Windows users with Git Bash
curl -sLS https://get.arkade.dev | sh`}
          </code>
        </pre>
      </>
    ),
  },
  {
    id: 2,
    title: 'Get the Actuated CLI',
    content: (
      <>
        <pre>
          <code>{`arkade get actuated-cli`}</code>
        </pre>
      </>
    ),
  },
  {
    id: 3,
    title: 'Configure the Actuated URL',
    content: (
      <>
        <pre>
          <code>
            {`echo 'export ACTUATED_URL=https://actuated-controller.o6s.io' >> ~/.bashrc
echo 'export ACTUATED_URL=https://actuated-controller.o6s.io' >> ~/.zshrc`}
          </code>
        </pre>
      </>
    ),
  },
  {
    id: 4,
    title: 'Authenticate the CLI',
    content: (
      <>
      <p>Get a personal access token with the appropriate scopes from GitHub.</p>
      <pre>
        <code>
          actuated-cli auth
        </code>
      </pre>
      </>
    ),
  }
];

export default function CLIPage() {
  return (
    <main>
      <div className="py-6">
        <div className="mx-auto max-w-5xl px-3 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Actuated CLI</h1>
          <p className="mt-2 text-base text-gray-700">
            Monitor Actuated runners and jobs from the command line.
          </p>
          <div className="mt-8">
            <Steps steps={steps} />
          </div>
        </div>
      </div>
    </main>
  );
}
