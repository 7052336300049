import { useEffect, useState } from 'react';

import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { classNames, nanoToMin } from './formatting';
import ChangeIndicator from './components/ChangeIndicator';
import {periods } from './ReportsPage'
import { getJobSummary } from './insights';

import { ChevronDownIcon, ComputerDesktopIcon } from '@heroicons/react/20/solid';

const SummaryCell = ({ value, change }) => (
  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
    <div className="flex justify-between">
      <div className="mr-1">{value}</div>
      <div className="flex-grow">
        <ChangeIndicator change={change} />
      </div>
    </div>
  </td>
);

function UserInsights({ api }) {
  const [orgs, setOrgs] = useState(api.getOwners() || []);
  const [selectedOrg, setSelectedOrg] = useState(
    localStorage.getItem('selectedOrg') || orgs[0]
  );
  const [selectedPeriod, setSelectedPeriod] = useState(
    localStorage.getItem('selectedPeriod') || 28
  );
  const [refreshing, setRefreshing] = useState(false);
  const [data, setData] = useState({ stats: [], summary: {} });

  const updateStats = async (org, period) => {
    setRefreshing(true);

    const today = 1;
    let includeToday = period == today;

    let stats = await api.getUserStats(period, includeToday)
    stats = stats.filter((stat) => stat.current.totalBuilds > 0 && stat.owner === org);

    let summary = getJobSummary(stats);

    stats = stats.map((stat) => ({
      actor: stat.actor,
      repo: stat.repo,
      ...stat.current,
    }));

    stats.sort((a, b) => {
      return (
        b.totalBuilds - a.totalBuilds || b.totalRunTimeNano - a.totalRunTimeNano
      );
    });

    setData({ stats, summary });
    setRefreshing(false);
  };

  useEffect(() => {
    updateStats(selectedOrg, selectedPeriod);
  }, [selectedOrg, selectedPeriod]);

  const selectPeriod = (period) => {
    localStorage.setItem('selectedPeriod', period);
    setSelectedPeriod(period);
  };

  const selectOrg = (org) => {
    localStorage.setItem('selectedOrg', org);
    setSelectedOrg(org);
  };

  let { summary } = data;

  return (
    <div className='relative'>
    <div className="overflow-hidden rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5">
      <div className="py-3 bg-gray-50 h-16">
        <div className="flex justify-end absolute right-0 left-0 px-4 sm:px-6">
          <Menu as="div" className="relative">
            <Menu.Button
              type="button"
              className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {selectedOrg ? selectedOrg : 'No org selected'}
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {orgs.map((org, index) => {
                    return (
                      <Menu.Item key={index}>
                        {({ active }) => (
                          <a
                            onClick={() => selectOrg(org)}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            {org}
                          </a>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <Menu as="div" className="relative">
            <Menu.Button
              type="button"
              className="ml-3 flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              { selectedPeriod == 1 ? 'Today' : `Last ${selectedPeriod} days`}
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {periods.map((period) => {
                    return (
                      <Menu.Item key={period.id}>
                        {({ active }) => (
                          <a
                            onClick={() => selectPeriod(period.value)}
                            className={classNames(
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            {period.text}
                          </a>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <div className="px-4 sm:px-6">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    User
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Builds
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Build minutes
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Successful
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Failed
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Cancelled
                  </th>
                </tr>
              </thead>
              {data && data.stats && data.stats.length ? (
                <>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.stats.map((stat) => (
                      <tr key={stat.actor}>
                        <td className="whitespace-nowrap px-2 py-2 py-2 pr-3 sm:pl-6 pl-2 text-sm font-medium text-gray-900">
                          <a
                            className="hover:underline hover:text-indigo-600 hover:text-indigo-500"
                            href={
                              'https://github.com/' +
                              stat.actor
                            }
                          >
                            {stat.actor}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                          {stat.totalBuilds}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                          {Math.round(nanoToMin(stat.totalRunTimeNano))}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                          {stat.totalSuccess}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                          {stat.totalFailure}
                        </td>
                        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                          {stat.totalCancelled}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="whitespace-nowrap px-2 py-4 pr-3 sm:pl-6 pl-2 text-left text-sm font-semibold text-gray-900">
                        Total
                      </td>
                      <SummaryCell
                        value={summary.builds.stat}
                        change={summary.builds.change}
                      />
                      <SummaryCell
                        value={summary.runtime.stat}
                        change={summary.runtime.change}
                      />
                      <SummaryCell
                        value={summary.success.stat}
                        change={summary.success.change}
                      />
                      <SummaryCell
                        value={summary.failed.stat}
                        change={summary.failed.change}
                      />
                      <SummaryCell
                        value={summary.cancelled.stat}
                        change={summary.cancelled.change}
                      />
                    </tr>
                  </tfoot>
                </>
              ) : (
                <tbody>
                  <tr key="empty-row">
                    <td
                      className="whitespace-nowrap px-2 py-8 pr-3 sm:pl-6 pl-2 text-center text-gray-900"
                      colSpan={6}
                    >
                      {refreshing ? (
                        <div className="flex justify-center">
                          <svg
                            className="animate-spin h-8 w-8 mr-3"
                            viewBox="0 0 24 24"
                          >
                            <path
                              className="opacity-75 fill-indigo-500"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                        </div>
                      ) : (
                        <p>No data available.</p>
                      )}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
export default UserInsights;
