import { Component } from 'react';
import { LockClosedIcon } from '@heroicons/react/20/solid'

export class LoginPage extends Component {

    render() {
        return (

        <>

      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-12">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="/images/actuated.png"
              alt="Actuated"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 mb-5">
              Sign-up for an actuated plan
            </h2>
            <p className="mb-2 text-lg font-semibold text-gray-900 ">
                There are three reasons that you may be seeing this screen:
            </p>
            <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
                <li>You haven't signed up for a subscription for your organisation.</li>
                <li>Your servers have not been enrolled by the actuated team yet.</li>
                <li>You need to <a href="https://docs.actuated.dev/troubleshooting/" className="underline hover:indigo-500">authorize the OAuth app</a> for your organisation.</li>
            </ul>
            <p className="text-md text-gray-500  mt-5">
                If you've completed both of the above steps, then <a className="underline hover:indigo-500" href={`${process.env.REACT_APP_API_URL || ""}/oauth/logout`}>Sign out here</a> to refresh your session and try again.
            </p>
          </div>

          <div className="mt-8 space-y-6 " >
            <a
              href="https://docs.actuated.dev/register/"
              className="inline-flex w-full justify-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 bg-indigo-600"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
            </svg>

              &nbsp;<span>Sign up</span>
            </a>
          </div>
        </div>
      </div>
      </>

        )
    }

}

export default LoginPage;

