import { useEffect, useState } from "react";
import { Fragment } from "react";
import {
  Menu,
  Transition,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { classNames, formatDuration, formatBytes } from "./formatting";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { XMarkIcon, ClockIcon } from "@heroicons/react/24/outline";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

const ColumnSort = ({ name, selected, children, onSelect }) => {
  const [sortOrder, setSortOrder] = useState(
    localStorage.getItem(`profilingSort_${name}`) || "desc"
  );

  const isActive = selected === name;

  const updateSortOrder = () => {
    const order = sortOrder === "desc" ? "asc" : "desc";

    localStorage.setItem(`profilingSort_${name}`, order);
    setSortOrder(order);

    return order;
  };

  return (
    <button
      className="group inline-flex items-center"
      onClick={() => {
        if (isActive) {
          onSelect(name, updateSortOrder());
        } else {
          onSelect(name, sortOrder);
        }
      }}
    >
      {children}
      <span
        className={classNames(
          "ml-2 flex-none rounded",
          isActive
            ? "bg-gray-200 text-gray-900 group-hover:bg-gray-200"
            : "invisible text-gray-400 group-hover:visible group-focus:visible"
        )}
      >
        {sortOrder === "desc" ? (
          <ChevronDownIcon aria-hidden="true" className="w-4 h-4" />
        ) : (
          <ChevronUpIcon aria-hidden="true" className="w-4 h-4" />
        )}
      </span>
    </button>
  );
};

function DetailDialog({ open, onClose, selected, api }) {
  const [snapshot, setSnapshot] = useState();
  const [loading, setLoading] = useState(false);

  const getSnapshot = async (job) => {
    setLoading(true);
    let snapshot = await api.getSnapshot(job.id, job.owner);

    setSnapshot({
      Spec: {
        "Total RAM": formatBytes(snapshot.total_memory),
        "Total vCPU": snapshot.total_cpu,
      },
      Load: {
        "Max 1m load average": snapshot.max_load_avg1,
        "Max 5m load average": snapshot.max_load_avg5,
        "Max 15m load average": snapshot.max_load_avg15,
      },
      Memory: {
        "Max RAM usage": formatBytes(
          snapshot.total_memory - snapshot.min_memory_available_gb
        ),
        "Max 5m avg RAM": formatBytes(snapshot.max_memory_avg5),
      },
      Disk: {
        "Disk (read/write)": `${formatBytes(
          snapshot.disk_read_total
        )} / ${formatBytes(snapshot.disk_write_total)}`,
        "Space (free/used/total)": `${formatBytes(
          snapshot.disk_space_free
        )} / ${formatBytes(snapshot.disk_space_used)} / ${formatBytes(
          snapshot.disk_space_total
        )}`,
        "Max I/O inflight": snapshot.max_disk_io_inflight,
      },
      Network: {
        "RX/TX": `${formatBytes(snapshot.network_read_total)} / ${formatBytes(
          snapshot.network_write_total
        )}`,
      },
      System: {
        "Entropy (min/max)": `${snapshot.min_entropy} / ${snapshot.max_entropy} `,
        "Max open files": snapshot.max_open_files,
        "Max open connection": snapshot.max_open_connections,
        "Processes since boot": snapshot.processes_since_boot,
      },
    });

    setLoading(false);
  };

  useEffect(() => {
    if (selected) {
      getSnapshot(selected);
    } else {
      setSnapshot(null);
    }
  }, [selected]);

  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="grow relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 pr-4 pt-4">
              <button
                type="button"
                onClick={() => onClose(false)}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </div>
            <div className="flex flex-col">
              <div className="px-4 sm:px-0">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold text-gray-900"
                >
                  <a
                    className="hover:underline hover:text-indigo-600 hover:text-indigo-500"
                    href={`https://github.com/${selected?.owner}/${selected?.repo}/runs/${selected?.id}`}
                    target="_blank"
                  >
                    {`${selected?.workflow}/${selected?.job}`}
                  </a>
                </DialogTitle>
                <div className="sm:min-w-full min-[445px]:grid min-[445px]:grid-cols-2 sm:gap-4 sm:px-0">
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    <a
                      className="hover:underline hover:text-indigo-600 hover:text-indigo-500"
                      href={`https://github.com/${selected?.owner}/${selected?.repo}`}
                      target="_blank"
                    >
                      {selected?.repo}
                    </a>
                  </p>
                  <p className="py-1 flex items-center text-sm text-gray-500">
                    <ClockIcon className="h-4 w-4" />
                    <span className="ml-1">
                      {formatDuration(selected?.runtime)}
                    </span>
                  </p>
                </div>
              </div>
              <div className="mt-2 border-t border-gray-100">
                {loading ? (
                  <div className="flex justify-center">
                    <svg
                      className="animate-spin h-8 w-8 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <path
                        className="opacity-75 fill-indigo-500"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  snapshot &&
                  Object.keys(snapshot).map((section) => {
                    return (
                      <dl key={section} className="divide-y divide-gray-100">
                        <div className="py-2 px-2 sm:px-0 text-sm font-medium text-gray-900">
                          {section}
                        </div>
                        {Object.keys(snapshot[section]).map((key) => {
                          return (
                            <div
                              key={key}
                              className="px-4 py-0.5 sm:min-w-full min-[445px]:grid min-[445px]:grid-cols-2 sm:gap-4 sm:px-0"
                            >
                              <dt className="text-sm  text-gray-900">{key}</dt>
                              <dd className="mt-1 text-sm text-gray-700 sm:mt-0">
                                {snapshot[section][key]}
                              </dd>
                            </div>
                          );
                        })}
                      </dl>
                    );
                  })
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

function ProfilingPage({ api }) {
  TimeAgo.setDefaultLocale(en.locale);
  TimeAgo.addLocale(en);

  const timeAgo = new TimeAgo("en-US");

  const limits = [20, 50, 100];
  const [refreshing, setRefreshing] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [snapshots, setSnapshots] = useState([]);
  const [selectedJob, setSelectedJob] = useState();
  const [sortBy, setSortBy] = useState(
    localStorage.getItem("profilingSortBy") || "completed"
  );

  let orgs = api.getOwners() || [];
  const [selectedOrg, setSelectedOrd] = useState(
    localStorage.getItem("selectedOrg") || orgs[0]
  );
  const [limit, setLimit] = useState(
    localStorage.getItem("selectedProfilingLimit") || 20
  );

  const openDetail = (snapshot) => {
    setSelectedJob({
      id: snapshot.job_id,
      owner: snapshot.owner,
      job: snapshot.job,
      workflow: snapshot.workflow,
      repo: snapshot.repo,
      runtime: snapshot.runtime,
    });
    setDetailOpen(true);
  };

  const refresh = async () => {
    setRefreshing(true);

    // Reload API data
    let snapshots = await api.getSnapshots(selectedOrg, limit);

    snapshots = snapshots.map((snapshot) => {
      const usedMemory =
        snapshot.total_memory - snapshot.min_memory_available_gb;
      const usedDisk = snapshot.disk_space_total - snapshot.disk_space_free;

      return {
        ...snapshot,

        // Convert average duration from microseconds to seconds
        average_runtime: snapshot.average_runtime / 1e9,

        // RAM usage
        memory: {
          total: snapshot.total_memory,
          used: usedMemory.toFixed(2),
          percentage: ((usedMemory / snapshot.total_memory) * 100).toFixed(2),
        },

        // Disk usage
        disk: {
          total: snapshot.disk_space_total,
          used: usedDisk.toFixed(2),
          percentage: ((usedDisk / snapshot.disk_space_total) * 100).toFixed(2),
        },

        // Runtime in seconds
        runtime:
          (new Date(snapshot.completed_at) - new Date(snapshot.started_at)) /
          1000,
      };
    });

    const order = localStorage.getItem(`profilingSort_${sortBy}`) || "desc";
    sortSnapshots(sortBy, snapshots, order);

    setSnapshots(snapshots);
    setRefreshing(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  const selectLimit = (limit) => {
    localStorage.setItem("selectedProfilingLimit", limit);
    setLimit(limit);
  };

  const selectOrg = (org) => {
    localStorage.setItem("selectedOrg", org);
    setSelectedOrd(org);
  };

  useEffect(() => {
    refresh();
  }, [limit, selectedOrg]);

  const LoadAverage = ({ cpu, load }) => {
    const free = (cpu - load).toFixed(2);
    return (
      <div>
        {load} <span className={free < 0 ? "text-red-600" : ""}>({free})</span>
      </div>
    );
  };

  const sortSnapshots = (field, snapshots, order) =>
    snapshots.sort((a, b) => {
      switch (field) {
        case "max_load_avg1":
          return order === "desc"
            ? b.max_load_avg1 - a.max_load_avg1
            : a.max_load_avg1 - b.max_load_avg1;
        case "max_load_avg5":
          return order === "desc"
            ? b.max_load_avg5 - a.max_load_avg5
            : a.max_load_avg5 - b.max_load_avg5;
        case "memory":
          return order === "desc"
            ? b.memory.percentage - a.memory.percentage
            : a.memory.percentage - b.memory.percentage;
        case "disk":
          return order === "desc"
            ? b.disk.percentage - a.disk.percentage
            : a.disk.percentage - b.disk.percentage;
        case "runtime":
          return order === "desc"
            ? b.runtime - a.runtime
            : a.runtime - b.runtime;
        case "repo":
          return order === "desc"
            ? a.repo.localeCompare(b.repo, undefined, { sensitivity: "base" })
            : b.repo.localeCompare(a.repo, undefined, { sensitivity: "base" });
        default:
          return order === "desc"
            ? new Date(a.completed_at) - new Date(b.completed_at)
            : new Date(b.completed_at) - new Date(a.completed_at);
      }
    });

  const onSelectColumnSort = (field, order) => {
    const s = sortSnapshots(field, [...snapshots], order);

    localStorage.setItem("profilingSortBy", field);
    setSortBy(field);
    setSnapshots(s);
  };

  return (
    <main>
      {selectedJob && (
        <DetailDialog
          api={api}
          open={detailOpen}
          onClose={() => {
            setDetailOpen(false);
          }}
          selected={selectedJob}
        />
      )}
      <div className="py-6">
        <div className="mx-auto px-4 sm:px-6 md:px-8">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Profiling
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Track resource usage of your jobs.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => refresh()}
                    type="button"
                    disabled={refreshing}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    {refreshing ? (
                      <svg
                        className="animate-spin h-5 w-5 mr-3"
                        viewBox="0 0 24 24"
                        visibility={refreshing ? "show" : "hidden"}
                      >
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      ""
                    )}
                    {refreshing ? "Refreshing" : "Refresh"}
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-4 px-4 sm:px-6 lg:px-8">
              <div className="relative">
                <div className="overflow-hidden rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5">
                  <div className="py-3 bg-gray-50 h-16">
                    <div className="flex justify-end absolute right-0 left-0 sm:mt-0 px-4 sm:px-6">
                      <Menu as="div" className="relative">
                        <Menu.Button
                          type="button"
                          className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                          {selectedOrg ? selectedOrg : "No org selected"}
                          <ChevronDownIcon
                            className="ml-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {orgs.map((org, index) => {
                                return (
                                  <Menu.Item key={index}>
                                    {({ active }) => (
                                      <a
                                        onClick={() => selectOrg(org)}
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        {org}
                                      </a>
                                    )}
                                  </Menu.Item>
                                );
                              })}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                      <Menu as="div" className="relative">
                        <Menu.Button
                          type="button"
                          className="ml-3 flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                          {`Last ${limit} jobs`}
                          <ChevronDownIcon
                            className="ml-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Menu.Button>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {limits.map((limit) => {
                                return (
                                  <Menu.Item key={limit}>
                                    {({ active }) => (
                                      <a
                                        onClick={() => selectLimit(limit)}
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        {`Last ${limit} jobs`}
                                      </a>
                                    )}
                                  </Menu.Item>
                                );
                              })}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="flow-root">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                <ColumnSort
                                  name="completed_at"
                                  selected={sortBy}
                                  onSelect={onSelectColumnSort}
                                >
                                  Completed
                                </ColumnSort>
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                <ColumnSort
                                  name="repo"
                                  selected={sortBy}
                                  onSelect={onSelectColumnSort}
                                >
                                  Repo
                                </ColumnSort>
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Job
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                vCPU
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                <ColumnSort
                                  name="max_load_avg1"
                                  selected={sortBy}
                                  onSelect={onSelectColumnSort}
                                >
                                  1m load avg.
                                </ColumnSort>
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                <ColumnSort
                                  name="max_load_avg5"
                                  selected={sortBy}
                                  onSelect={onSelectColumnSort}
                                >
                                  5m load avg.
                                </ColumnSort>
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                <ColumnSort
                                  name="memory"
                                  selected={sortBy}
                                  onSelect={onSelectColumnSort}
                                >
                                  Max RAM usage
                                </ColumnSort>
                              </th>

                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                <ColumnSort
                                  name="disk"
                                  selected={sortBy}
                                  onSelect={onSelectColumnSort}
                                >
                                  Disk usage
                                </ColumnSort>
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                <ColumnSort
                                  name="runtime"
                                  selected={sortBy}
                                  onSelect={onSelectColumnSort}
                                >
                                  Runtime
                                </ColumnSort>
                              </th>
                              <th
                                scope="col"
                                className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              ></th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {snapshots.length ? (
                              snapshots.map((snapshot) => (
                                <tr key={snapshot.job_id}>
                                  <td className="whitespace-nowrap px-2 py-2 py-2 pr-3 sm:pl-6 pl-2 text-sm font-medium text-gray-900">
                                    {timeAgo.format(
                                      new Date(snapshot.completed_at),
                                      "mini"
                                    ) + " ago"}
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                    <a
                                      className="hover:underline hover:text-indigo-600 hover:text-indigo-500"
                                      href={`https://github.com/${snapshot.owner}/${snapshot.repo}`}
                                      target="_blank"
                                    >
                                      {snapshot.repo}
                                    </a>
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                    <div className="flex flex-col">
                                      <span>{snapshot.workflow}</span>
                                      <a
                                        className="text-xs text-gray-600 hover:underline hover:text-indigo-600 hover:text-indigo-500"
                                        href={`https://github.com/${snapshot.owner}/${snapshot.repo}/runs/${snapshot.job_id}`}
                                        target="_blank"
                                      >
                                        {snapshot.job}
                                      </a>
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                    {snapshot.total_cpu}
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                    <LoadAverage
                                      cpu={snapshot.total_cpu}
                                      load={snapshot.max_load_avg1}
                                    />
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                    <LoadAverage
                                      cpu={snapshot.total_cpu}
                                      load={snapshot.max_load_avg5}
                                    />
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                    <div className="flex flex-col">
                                      <span>
                                        {snapshot.memory.used}GB (
                                        {snapshot.memory.percentage}%)
                                      </span>
                                      <span className="text-xs text-gray-600">
                                        of {snapshot.memory.total}GB
                                      </span>
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                    <div className="flex flex-col">
                                      <span>
                                        {snapshot.disk.used}GB (
                                        {snapshot.disk.percentage}%)
                                      </span>
                                      <span className="text-xs text-gray-600">
                                        of {snapshot.disk.total}GB
                                      </span>
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                    <div className="flex flex-col">
                                      <span>
                                        {formatDuration(snapshot.runtime)}
                                      </span>
                                      {snapshot.average_runtime && (
                                        <span className="text-xs text-gray-600">
                                          avg.{" "}
                                          {formatDuration(
                                            snapshot.average_runtime
                                          )}
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                    <button
                                      type="button"
                                      className="inline-flex z-0 items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                                      onClick={() => openDetail(snapshot)}
                                    >
                                      Details
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr key="empty-row">
                                <td
                                  className="whitespace-nowrap px-2 py-8 pr-3 sm:pl-6 pl-2 text-center text-gray-900"
                                  colSpan={9}
                                >
                                  {refreshing ? (
                                    <div className="flex justify-center">
                                      <svg
                                        className="animate-spin h-8 w-8 mr-3"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          className="opacity-75 fill-indigo-500"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                      </svg>
                                    </div>
                                  ) : (
                                    <p>No profiling data.</p>
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ProfilingPage;
