import { useEffect, useState } from 'react';
import { DateTime } from "luxon";

function EventsPage({ api }) {
  const [refreshing, setRefreshing] = useState(false);
  const [events, setEvents] = useState([]);

  const updateEvents = async () => {
    setRefreshing(true);

    let events = await api.getEvents();

    events.sort((a ,b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })

    setEvents(events);
    setRefreshing(false);
  };

  useEffect(() => {
    updateEvents();
  }, []);

  return (
    <main>
      <div className="py-6">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Events
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Track events for your jobs.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => updateEvents()}
                    type="button"
                    disabled={refreshing}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  >
                    {refreshing ? (
                      <svg
                        className="animate-spin h-5 w-5 mr-3"
                        viewBox="0 0 24 24"
                        visibility={refreshing ? 'show' : 'hidden'}
                      >
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      ''
                    )}
                    {refreshing ? 'Refreshing' : 'Refresh'}
                  </button>
                </div>
              </div>
            </div>
            
            <div className="mt-4 px-4 sm:px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5">
              <div className="px-4 sm:px-6">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Time
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Message
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Organisation
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {events.length ? (
                          events.map((event) => (
                              <tr key={event.created_at}>
                                <td className="whitespace-nowrap px-2 py-2 py-2 pr-3 sm:pl-6 pl-2 text-sm font-medium text-gray-900">
                                  {DateTime.fromISO(event.created_at).toFormat('yyyy-MM-dd')}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                  {DateTime.fromISO(event.created_at).toFormat('HH:mm:ss')}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                  {event.message}
                                </td>
                                <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                  {event.owner}
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr key="empty-row">
                            <td
                              className="whitespace-nowrap px-2 py-8 pr-3 sm:pl-6 pl-2 text-center text-gray-900"
                              colSpan={6}
                            >
                              {refreshing ? (
                                <div className="flex justify-center">
                                  <svg
                                    className="animate-spin h-8 w-8 mr-3"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      className="opacity-75 fill-indigo-500"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                </div>
                              ) : (
                                <p>No recent events.</p>
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </main>
  );
}

export default EventsPage;
