import { useEffect, useState } from 'react';
import { getJobSummary } from './insights';
import ChangeIndicator from './components/ChangeIndicator';

function Home({ api }) {
  const [period, setPeriod] = useState(1);
  const [refreshing, setRefreshing] = useState(false);
  const [repoStats, setRepoStats] = useState({});

  const updateStats = async () => {
    setRefreshing(true);

    let includeToday = true;
    let stats = await api.getRepoStats(period, includeToday);
    let summary = getJobSummary(stats);

    setRepoStats(summary);
    setRefreshing(false);
  };

  useEffect(() => {
    updateStats();
  }, [period]);

  return (
    <main>
      <div className="py-6">
        <div className="mx-auto max-w-7xl px-3 sm:px-6 md:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-semibold text-gray-900">
                Today's jobs so far
              </h1>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                onClick={() => updateStats()}
                type="button"
                disabled={refreshing}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                {refreshing ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                    visibility={refreshing ? 'show' : 'hidden'}
                  >
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  ''
                )}
                {refreshing ? 'Refreshing' : 'Refresh'}
              </button>
            </div>
          </div>

          <div className="py-4">
            {refreshing ? (
              <div className="p-10 flex justify-center bg-white shadow sm:rounded-lg">
                <svg className="animate-spin h-8 w-8 mr-3" viewBox="0 0 24 24">
                  <path
                    className="opacity-75 fill-indigo-500"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              <>
                {repoStats.builds && (
                  <div className="grid grid-cols-1 md:grid-cols-5 md:gap-4">
                    <dl className="mt-5 col-span-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-2 md:divide-x md:divide-y-0">
                      <div className="px-4 py-3 sm:p-4">
                        <dt className="text-base font-normal text-gray-900">
                          Builds
                        </dt>
                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                          <div className="flex items-baseline text-2xl font-semibold text-black">
                            {repoStats.builds.stat}
                            <span className="md:hidden ml-2 text-sm font-medium text-gray-500">
                              vs. {repoStats.builds.preceding}
                            </span>
                          </div>
                          <ChangeIndicator change={repoStats.builds.change} />
                        </dd>
                      </div>

                      <div className="px-4 py-3 sm:p-4">
                        <dt className="text-base font-normal text-gray-900">
                          Build minutes
                        </dt>
                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                          <div className="flex items-baseline text-2xl font-semibold text-black">
                            {repoStats.runtime.stat}
                            <span className="md:hidden ml-2 text-sm font-medium text-gray-500">
                              vs. {repoStats.runtime.preceding}
                            </span>
                          </div>
                          <ChangeIndicator change={repoStats.runtime.change} />
                        </dd>
                      </div>
                    </dl>
                    <dl className="hidden md:grid mt-5 col-span-3 grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
                      <div className="px-4 py-3 sm:p-4">
                        <dt className="text-base font-normal text-gray-900">
                          Success
                        </dt>
                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                          <div className="flex items-baseline text-2xl font-semibold text-black">
                            {repoStats.success.stat}
                            <span className="md:hidden ml-2 text-sm font-medium text-gray-500">
                              vs. {repoStats.success.preceding}
                            </span>
                          </div>
                          <ChangeIndicator change={repoStats.success.change} />
                        </dd>
                      </div>

                      <div className="px-4 py-3 sm:p-4">
                        <dt className="text-base font-normal text-gray-900">
                          Failed
                        </dt>
                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                          <div className="flex items-baseline text-2xl font-semibold text-black">
                            {repoStats.failed.stat}
                            <span className="md:hidden ml-2 text-sm font-medium text-gray-500">
                              vs. {repoStats.failed.preceding}
                            </span>
                          </div>
                          <ChangeIndicator change={repoStats.failed.change} />
                        </dd>
                      </div>

                      <div className="px-4 py-3 sm:p-4">
                        <dt className="text-base font-normal text-gray-900">
                          Cancelled
                        </dt>
                        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                          <div className="flex items-baseline text-2xl font-semibold text-black">
                            {repoStats.cancelled.stat}
                            <span className="md:hidden ml-2 text-sm font-medium text-gray-500">
                              vs. {repoStats.cancelled.preceding}
                            </span>
                          </div>
                          <ChangeIndicator
                            change={repoStats.cancelled.change}
                          />
                        </dd>
                      </div>
                    </dl>
                  </div>
                )}
              </>
            )}

            <h1 className="mt-6 text-2xl font-semibold text-gray-900">Tips</h1>
            <div className="mt-5 grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-3 py-3 sm:p-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Enable a build cache 🔥
                  </h3>
                  <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                      Get container images instantly and avoid rate-limiting
                      from the Docker Hub.
                    </p>
                  </div>
                  <div className="mt-3 text-sm">
                    <a
                      href="https://docs.actuated.dev/tasks/registry-mirror/"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Setup a mirror
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-3 py-3 sm:p-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Matrix builds 🤹
                  </h3>
                  <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                      Run parts of a build in parallel to reduce the overall
                      time taken.
                    </p>
                  </div>
                  <div className="mt-3 text-sm">
                    <a
                      href="https://docs.actuated.dev/examples/matrix/#try-out-the-action-on-your-agent"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Read an example
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-3 py-3 sm:p-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Want Grafana metrics and server monitoring? 📈
                  </h3>
                  <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>Email us to learn about our add-on product which you can scrape with Prometheus or Grafana.</p>
                  </div>
                  <div className="mt-3 text-sm">
                    <a
                      href="mailto:support@openfaas.com"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Email us now
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-3 py-3 sm:p-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Talk to us 💬
                  </h3>
                  <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                      Chat with our team, give feedback, get technical support or upgrade your plan.
                    </p>
                  </div>
                  <div className="mt-3 text-sm">
                    <a
                      href="https://self-actuated.slack.com"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Log into Slack
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
