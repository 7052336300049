import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import AppRouter from './AppRouter';
import Base from './Base';
import { Component } from 'react';
import Cookies from 'universal-cookie';
import LoginPage from './Login';
import SignUpPage from './SignUpPage';
import Api from './api';

const cookies = new Cookies();

export class App extends Component {
  render() {
    // https://blog.logrocket.com/react-router-v6/

    let api = new Api();
    let token = cookies.get("actuated_dashboard");
    if(!token) {
      return (
          <Router>
          <div className="container mx-auto">

                <LoginPage />

            </div>
            </Router>
     )
    } else {
      let owners = api.getOwners();
      if(owners.length == 0 || owners[0] == "") {
        return (
          <Router>
          <div className="container mx-auto">

                <SignUpPage />

            </div>
            </Router>
        )
      }
    }

      return (
      <Router>

        <Base Component={AppRouter}></Base>

      </Router>
      )
  }
}

export default App;
