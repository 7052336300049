import { Component } from 'react';

import { NavLink, Outlet } from 'react-router-dom';
import { classNames } from './formatting';

const tabs = [
  { name: 'Organisations', href: "organisations", current: true },
  { name: 'Job Outliers', href: 'outliers', current: false },
  { name: 'Repositories', href: "repositories", current: false },
  { name: 'Users', href: "users", current: false },
];

export const periods = [
  { id: 1, text: 'Last 28 days', value: 28 },
  { id: 2, text: 'Last 14 days', value: 14 },
  { id: 3, text: 'Last 7 days', value: 7 },
  { id: 4, text: 'Today', value: 1 },
];

class ReportsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <main>
        <div className="py-6">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
            <div className="relative border-b border-gray-200 pb-5 pb-0 mb-8">
              <div className="md:flex md:items-center md:justify-between">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Insights
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Track usage across your organisation.
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <div className="sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                      <NavLink
                        key={tab.name}
                        to={tab.href}
                        className={({ isActive }) => classNames(
                          isActive
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                          'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                      >
                        {tab.name}
                      </NavLink>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
            <div className="px-4 sm:px-6 lg:px-8">
              <Outlet />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ReportsPage;
