import React from 'react';
import { Fragment, useState } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Dialog, Transition } from '@headlessui/react';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

function RunnerWarning() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              Some runners are unreachable.&nbsp;
              <span
                onClick={openModal}
                className="font-medium text-yellow-700 underline hover:text-yellow-600"
              >
                Troubleshoot.
              </span>
            </p>
          </div>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Troubleshoot
                    </Dialog.Title>
                    <div className="mt-2">
                      <ol className="list-decimal px-4 text-sm text-gray-500">
                        <li className="mb-2">
                          Check the agent endpoint is accessible on the internet
                        </li>
                        <li className="mb-2">Check the DNS entry for the endpoint matches</li>
                        <li className="mb-2">
                          Check the agent logs:
                          <div className="font-semibold">
                            sudo journalctl -u actuated --since today
                          </div>
                        </li>
                      </ol>
                      <a
                        href="https://docs.actuated.dev/troubleshooting/"
                        onClick={openModal}
                        className="my-6 font-sm text-blue-700 underline hover:text-blue-500"
                      >
                        Troubleshooting docs
                      </a>
                    </div>
                  </div>

                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const ToolTip = ({ children, text }) => {
  return (
    <div className="group relative">
      {children}
      <div className="absolute group-hover:flex hidden ">
        <span className="fixed bg-gray-500 text-sm text-gray-100 rounded-md px-4 py-1 my-2">
          {text}
        </span>
      </div>
    </div>
  );
};

const RunnerStatusIcon = ({ status }) => {
  switch (status) {
    case 'running':
      return (
        <ToolTip text="Running">
          <div className="rounded-full w-2.5 h-2.5 bg-green-400"></div>
        </ToolTip>
      );
    case 'starting':
      return (
        <ToolTip text="Starting">
          <div className="rounded-full w-2.5 h-2.5 bg-gray-400"></div>
        </ToolTip>
      );
    case 'draining':
      return (
        <ToolTip text="Draining">
          <div className="rounded-full w-2.5 h-2.5 bg-gray-400"></div>
        </ToolTip>
      );
    default:
      return (
        <ToolTip text="Offline">
          <div className="rounded-full w-2.5 h-2.5 bg-red-400"></div>
        </ToolTip>
      );
  }
};

class RunnersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setState({ runners: props.runners });

    TimeAgo.setDefaultLocale(en.locale)
    TimeAgo.addLocale(en)
    this.state.timeAgo = new TimeAgo('en-US')

  }

  render() {
    let runners = this.props.runners;
    console.log(
      'Rendering: props',
      this.props.runners,
      'state',
      this.state.runners
    );

    let formatArch = (arch) => {
      if (!arch) {
        return (
          <ToolTip text="unreachable">
            <span>-</span>
          </ToolTip>
          )
      } else if (arch == "arm64") {
        return (
        <ToolTip text="arm64">
          <span>💪</span>
        </ToolTip>
        )
      } else {
        return (
          <ToolTip text="amd64">
            <span>🖥️</span>
          </ToolTip>
          )
      }
    }

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Available runners
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Runners registered for your organisation
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              onClick={this.props.onRefresh}
              type="button"
              disabled={this.props.refreshing}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              {this.props.refreshing ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3"
                  viewBox="0 0 24 24"
                  visibility={this.props.refreshing ? 'show' : 'hidden'}
                >
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                ''
              )}
              {this.props.refreshing ? 'Refreshing' : 'Refresh'}
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="mb-4">
            {runners && runners.some((runner) => runner.reachable == false) && (
              <RunnerWarning onTroubleshoot={this.props.onTroubleshoot} />
            )}
          </div>
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="whitespace-nowrap py-3.5 pl-4 pr-3 sm:pl-6"
                      ></th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell lg:table-cell"
                      >
                        Customer
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Ping
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        RAM
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Free RAM
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        CPUs
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        VMs
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell lg:table-cell"
                      >
                        Arch.
                      </th>
                      <th
                        scope="col"
                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell lg:table-cell"
                      >
                        Agent up
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {runners ? (
                      runners.map((runner) => (
                        <tr key={runner.name}>
                          <td className="whitespace-nowrap w-4 px-2 py-2 sm:pl-6 pl-2 text-sm font-medium text-gray-900">
                            <RunnerStatusIcon status={runner.status} />
                          </td>
                          <td className="whitespace-nowrap px-2 py-4 py-2 pr-3 text-sm font-medium text-gray-900">
                            {runner.name}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 hidden md:table-cell lg:table-cell">
                            {runner.customer}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {runner.reachable
                              ? (runner.pingNano / 1000 / 1000).toFixed(0) +
                                'ms'
                              : '-'}{' '}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {runner.reachable
                              ? Math.floor(
                                  runner.memory / 1000 / 1000 / 1000
                                ).toFixed(0) + 'GB'
                              : '-'}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {runner.reachable
                              ? Math.floor(
                                  runner.memoryAvailable / 1000 / 1000 / 1000
                                ).toFixed(0) + 'GB'
                              : '-'}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {runner.reachable ? runner.cpus : '-'}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                            {runner.reachable
                              ? `${runner.vms}/${runner.maxVms}`
                              : '-'}
                          </td>

                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 hidden md:table-cell lg:table-cell">
                            {formatArch(runner.arch)}
                          </td>

                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 hidden md:table-cell lg:table-cell">
                            {runner.reachable ? (
                              this.state.timeAgo.format(new Date(new Date().getTime() - (runner.uptimeNano / 1000 / 1000)), 'mini')
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key="empty-row">
                        <td
                          className="whitespace-nowrap px-2 py-4 py-2 pr-3 sm:pl-6 pl-2 text-sm text-gray-900"
                          colSpan={8}
                        >
                          No runners available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RunnersTable;
