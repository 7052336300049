import React, { useState, useEffect, useRef } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { classNames, nanoToMin, minsToDuration } from "./formatting";

import { ChevronDownIcon } from "@heroicons/react/20/solid";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Link } from "react-router-dom";

export const outlierPeriods = [
  { id: 1, text: "Last 120 days", value: 120 },
  { id: 2, text: "Last 90 days", value: 90 },
  { id: 3, text: "Last 30 days", value: 30 },
  { id: 4, text: "Last 14 days", value: 14 },
];

const DailyMaximumsChart = ({ data, tooltipTitle = "" }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="displayDate" />
        <YAxis interval="preserveStartEnd" />
        <Tooltip
          trigger="click"
          pointer-events="auto"
          content={<BuildMaximumTooltip title={"Maximum duration"} />}
        />
        <Legend formatter={renderLegendText} align="left" />
        <Line
          type="monotone"
          dataKey="maxDuration"
          stroke="#8884d8"
          strokeWidth={3}
          legendType="plainline"
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

const BuildMaximumTooltip = ({ title, active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    const hasData = data.jobID != 0;

    return (
      <div
        className="bg-white shadow sm:rounded-lg ring-1 ring-black ring-opacity-5"
        style={{ pointerEvents: "auto" }}
      >
        <div className="px-4 py-4">
          <p className="truncate text-sm font-medium text-gray-500">
            {`${data.displayDate}`}
          </p>

          <p className="mt-2 text-base font-semibold text-gray-900">
            {data.maxDurationFormatted}{" "}
            {!hasData && (
              <span className="font-medium text-gray-500">No data.</span>
            )}
            {hasData && (
              <a
                href={`https://github.com/${data.owner}/${data.repo}/runs/${data.jobID}`}
                target="_blank"
                rel="noreferrer"
                className="text-indigo-600 hover:text-indigo-500"
              >
                View Job
              </a>
            )}
          </p>
        </div>
      </div>
    );
  }

  return null;
};

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const renderLegendText = (value, entry) => {
  const { color } = entry;
  var v = value;
  if (value == "maxDuration") {
    v = "Max Duration (mins)";
  }

  return (
    <span className="font-medium" style={{ color }}>
      {capitalize(v)}
    </span>
  );
};

function InspectCard({ data }) {
  const ref = useRef(null);

  // scroll into view on data change
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [data]);

  return (
    <div
      className="overflow-visible rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5 z-0 mt-10"
      ref={ref}
    >
      <div className="px-4 py-3 sm:px-6">
        <div className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
          Maximum build minutes for selected week
        </div>

        <div className="h-80">
          <DailyMaximumsChart
            tooltipTitle="Increases for selected week"
            data={data || []}
            unit="min"
          ></DailyMaximumsChart>
        </div>
      </div>
    </div>
  );
}

function OutlierCard({ api }) {
  const [orgs, setOrgs] = useState(api.getOwners || []);
  const [selectedOrg, setSelectedOrg] = useState(
    localStorage.getItem("selectedOrg") || orgs[0]
  );
  const [selectedPeriod, setSelectedPeriod] = useState(
    localStorage.getItem("selectedOutlierPeriod") || 28
  );
  const [refreshing, setRefreshing] = useState(false);
  const [loadingWeekStats, setLoadingWeekstats] = useState(null);
  const [stats, setStats] = useState([]);
  const [weekStats, setWeekStats] = useState([]);

  const selectPeriod = (period) => {
    localStorage.setItem("selectedOutlierPeriod", period);
    setSelectedPeriod(period);
  };

  const selectOrg = (org) => {
    localStorage.setItem("selectedOrg", org);
    setSelectedOrg(org);
  };

  useEffect(() => {
    updateStats(selectedOrg, selectedPeriod);
  }, [selectedOrg, selectedPeriod]);

  const updateStats = async (org, selectedPeriod) => {
    setRefreshing(true);

    const now = new Date();
    const days = selectedPeriod;
    const startDate = now.setDate(now.getDate() - days);

    let stats = await api.getJobIncreases(org, startDate);
    // stats = stats.filter((stat) => stat.current.totalBuilds > 0 && stat.owner === org);

    setStats(stats);
    setRefreshing(false);
  };

  const inspectBuild = (stat, statIdx) => {
    setLoadingWeekstats(statIdx);

    for (let i = 0; i < stats.length; i++) {
      stats[i].isSelected = false;
    }

    stats[statIdx].isSelected = true;

    api
      .getDailyMaximumsByJob(
        stat.owner,
        stat.repo,
        stat.workflow_name,
        stat.job_name,
        stat.current_year,
        stat.current_week
      )
      .then((weekStatsResponse) => {
        var weekRows = [];

        let startDate = new Date(weekStatsResponse.periodStart);
        // let startDate = weekStatsResponse.periodStart;
        for (let i = 0; i < 7; i++) {
          weekRows.push({
            date: startDate,
            displayDate: startDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            }),
            maxDuration: 0,
            jobID: 0,
            owner: stat.owner,
            repo: stat.repo,
          });
          startDate = new Date(startDate.setDate(startDate.getDate() + 1));
        }

        for (let i = 0; i < weekStatsResponse.builds.length; i++) {
          for (let j = 0; j < weekRows.length; j++) {
            const placeHolder = weekRows[j].date.toDateString();
            const dataRow = new Date(
              weekStatsResponse.builds[i].day
            ).toDateString();

            if (placeHolder === dataRow) {
              const mins = nanoToMin(
                weekStatsResponse.builds[i].max_durationNano
              );
              weekRows[j].maxDurationFormatted = minsToDuration(mins);
              weekRows[j].maxDuration = mins;
              weekRows[j].jobID = weekStatsResponse.builds[i].job_id;
            }
          }
        }

        setStats(stats);
        setWeekStats(weekRows);
        setLoadingWeekstats(null);
      });
  };

  return (
    <div className="relative">
      <div
        className="overflow-hidden rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5"
        id="outlier-card-view"
      >
        <div className="py-3 bg-gray-50 h-16">
          <div className="flex justify-end absolute right-0 left-0 px-4 sm:px-6">
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                {selectedOrg ? selectedOrg : "No org selected"}
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {orgs.map((org, index) => {
                      return (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <a
                              onClick={() => selectOrg(org)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              {org}
                            </a>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <Menu as="div" className="relative">
              <Menu.Button
                type="button"
                className="ml-3 flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                {`Last ${selectedPeriod} days`}
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {outlierPeriods.map((period) => {
                      return (
                        <Menu.Item key={period.id}>
                          {({ active }) => (
                            <a
                              onClick={() => selectPeriod(period.value)}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              {period.text}
                            </a>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <div className="px-4 sm:px-6">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Repository
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Workflow
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Job
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Year
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Week
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Year
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Week
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Increase (mins)
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Inspect</span>
                    </th>
                  </tr>
                </thead>
                {stats && stats.length ? (
                  <>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {stats.map((stat, statIdx) => (
                        <tr
                          key={
                            stat.owner +
                            "-" +
                            stat.repo +
                            "-" +
                            stat.workflow_name +
                            "-" +
                            stat.job_name +
                            "-" +
                            stat.current_year +
                            "-" +
                            stat.current_week
                          }
                        >
                          <td className="whitespace-nowrap px-2 py-2 py-2 pr-3 sm:pl-6 pl-2 text-sm font-medium text-gray-900">
                            <a
                              className="hover:underline hover:text-indigo-600 hover:text-indigo-500"
                              href={
                                "https://github.com/" +
                                stat.owner +
                                "/" +
                                stat.repo +
                                "/actions"
                              }
                            >
                              {stat.repo}
                            </a>
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {stat.workflow_name}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {stat.job_name}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {stat.current_year}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {stat.current_week}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {stat.previous_year}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {stat.previous_week}
                          </td>
                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                            {Math.round(stat.duration_difference, 2)}m+
                          </td>
                          <td
                            className={classNames(
                              statIdx === 0
                                ? ""
                                : "border-t border-transparent",
                              "relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                            )}
                          >
                            <button
                              type="button"
                              className="inline-flex z-0 items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                              disabled={stat.isSelected}
                              onClick={() => inspectBuild(stat, statIdx)}
                            >
                              {loadingWeekStats === statIdx ? (
                                <svg
                                  className="animate-spin h-5 w-5 mr-3"
                                  viewBox="0 0 24 24"
                                  visibility={
                                    loadingWeekStats === statIdx
                                      ? "show"
                                      : "hidden"
                                  }
                                >
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              ) : (
                                ""
                              )}
                              Inspect<span className="sr-only"></span>
                            </button>
                            {statIdx !== 0 ? (
                              <div className="absolute -top-px left-0 right-6 h-px bg-gray-200" />
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : (
                  <tbody>
                    <tr key="empty-row">
                      <td
                        className="whitespace-nowrap px-2 py-8 pr-3 sm:pl-6 pl-2 text-center text-gray-900"
                        colSpan={8}
                      >
                        {refreshing ? (
                          <div className="flex justify-center">
                            <svg
                              className="animate-spin h-8 w-8 mr-3"
                              viewBox="0 0 24 24"
                            >
                              <path
                                className="opacity-75 fill-indigo-500"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </div>
                        ) : (
                          <p>No increases for period.</p>
                        )}
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      {weekStats && weekStats.length ? (
        <InspectCard data={weekStats}></InspectCard>
      ) : null}
    </div>
  );
}

export default OutlierCard;
