import Cookies from 'universal-cookie';
const jose = require('jose');

class User {
    constructor() {
        let cookie = new Cookies();
        let token = cookie.get("actuated_dashboard");

        this.claims = jose.decodeJwt(token)
    }

    getLogin() {
        return this.claims.login
    }
}

export default User;
