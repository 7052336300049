import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import Home from './Home';

import Runners from './Runners';
import NotFound from './NotFound';
import JobQueue from './JobQueue';
import ReportsPage from './ReportsPage';
import ProfilingPage from './ProfilingPage';

import Api from './api';
import ReportCard from './ReportCard';
import ReportTable from './ReportTable';
import OutlierCard from './OutlierCard';
import EventsPage from './EventsPage';
import CLIPage from './CLIPage';
import UserInsights from './UserInsights';
import SSHDebugPage from './SSHDebugPage';

function AppRouter() {
  let api = new Api();

  return (
    <Routes>
      <Route exact path="/" element={<Home api={api} />} />
      <Route path="/runners" element={<Runners api={api} />} />
      <Route path="/queue" element={<JobQueue api={api} />} />
      <Route path="reports" element={<ReportsPage />}>
        <Route index element={<Navigate to="organisations" />} />
        <Route path="organisations" element={<ReportCard api={api} />} />
        <Route path="outliers" element={<OutlierCard api={api} />} />
        <Route path="repositories" element={<ReportTable api={api} />} />
        <Route path="users" element={<UserInsights api={api} />} />
      </Route>
      <Route path="/events" element={<EventsPage api={api} />} />
      <Route path="/profiling" element={<ProfilingPage api={api} />} />
      <Route path="/cli" element={<CLIPage />} />
      <Route path="/ssh-sessions" element={<SSHDebugPage api={api}/>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRouter;
