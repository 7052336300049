import { Component } from 'react';
import RunnersTable from './RunnersTable';

class Runners extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      refreshing: false,
    }

    this.onRefresh = this.onRefresh.bind(this);
  }

  onRefresh() {
    this.setState({ refreshing: true });
    this.props.api.getRunners().then((data) => {
      this.setState({ runners: data });
      this.setState({ refreshing: false });
    });

  }

  componentDidMount() {
    console.log("Refreshing runners")

    this.setState({ refreshing: true });
    this.props.api.getRunners().then((data) => {
      this.setState({ runners: data });
      this.setState({ refreshing: false });
    });
  }

  render() {
    return (
    <main>
    <div className="py-6">

      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
        <div className="py-4">
          <div className="h-96">
          <RunnersTable runners={this.state.runners} refreshing={this.state.refreshing} onRefresh={this.onRefresh}></RunnersTable>
          </div>
        </div>
        {/* /End replace */}
      </div>
    </div>
  </main>
    )
  }
}


export default Runners;