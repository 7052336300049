import { Component } from 'react';
import { LockClosedIcon } from '@heroicons/react/20/solid'

export class LoginPage extends Component {

    render() {
        return (

        <>

      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-12">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="/images/actuated.png"
              alt="Actuated"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign-in to actuated with GitHub
            </h2>
          </div>

          <div className="mt-8 space-y-6 " >

              <a
                href={`${process.env.REACT_APP_API_URL || ""}/oauth/login`}
                className="inline-flex w-full justify-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 bg-indigo-600"
              >
                <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                    clipRule="evenodd"
                  />
                </svg>
                &nbsp;<span className="ml-2">Sign-in</span>

              </a>
      </div>
      <p className="mt-5 text-center text-sm text-gray-500">
      Not registered for actuated yet? <a href="https://docs.actuated.dev/register/" className="font-medium underline hover:text-indigo-500">Sign up</a> for a subscription plan for your GitHub organisation.
      </p>

    </div>
  </div>
      </>

        )
    }

}

export default LoginPage;

